<template>
    <BaseStrapiPage v-if="pageLoaded" />
</template>

<script setup>
import {usePageStore} from "@/store-pinia/page";

definePageMeta({middleware: 'prefetch'})

const pageStore = usePageStore()
const pageLoaded = computed(() => pageStore.pageLoaded)
</script>
